import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

function ProtectedRoute({ path, element }) {
    const { isLoggedIn } = useContext(AuthContext);

    return isLoggedIn ? (
        <Route path={path} element={element} />
    ) : (
        <Navigate to="/login" replace />
    );
}

export default ProtectedRoute;