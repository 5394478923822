import {App, Block, Link, Navbar, Page, Preloader} from "konsta/react";
import {useContext} from "react";
import {AuthContext} from "../AuthProvider";
import {Navigate} from "react-router-dom";
import logo from "../trihaka.png"
function Layout({children, title, subtitle, right }) {
    const {logout, isLoggedIn, isLoading} = useContext(AuthContext)

    return <App theme="ios" safeAreas>
        <Page>
            <Navbar
                title={title || ""}
                subtitle={subtitle || ""}
                className="top-0 sticky"
                right={isLoggedIn?<Link navbar onClick={() => logout()}>Logout</Link>:(right || <></>)}
            />
            <Block>
                <img className="mx-auto" width="150" src={logo}/>
            </Block>
            {isLoading?<Block strong insetMaterial outlineIos className="text-center">
                <Preloader />
            </Block>:children}
        </Page>
    </App>
}

export default Layout