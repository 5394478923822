import {Block, BlockTitle, Button, List, ListInput} from "konsta/react";
import Layout from "../Components/Layout";
import {Navigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthProvider";
import {fetchAPI} from "../api";
import * as moment from "moment";
function DashboardPage() {
    const {isLoggedIn, user, loading} = useContext(AuthContext)
    const [archives, setArchives] = useState([])
    useEffect(() => {
        loading(true)
        fetchAPI("staff/").then(r => {
            setArchives(r.data)
            console.log(r.data)
            loading(false)
        })
    },[])
    if(!isLoggedIn){
        return <Navigate to={"/login"}/>
    }
    const submit = (e) => {
        e.preventDefault()
        loading(true)
        const form = new FormData(e.target)
        fetchAPI("staff", {
            method: "POST",
            body: form,
        }).then(r => {
            if(r.ok){
                fetchAPI("staff/").then(r => {
                    setArchives(r.data)
                    loading(false)
                })
                alert("Berhasil menyimpan data report hari ini")
            }else{
                alert("Gagal")
                loading(false)
            }
        })
    }
    return <Layout title={"Staff"} subtitle={"PT. Tritama Hasil Karya Bersama"}>
        <div className="relative">
            {archives.includes(moment().format("YYYY_MM_DD"))?
                <Block strong inset>
                    <p>
                        Selamat datang <b>{user.nama}</b><br/>Terima kasih Anda sudah melaporkan pekerjaan Anda.
                    </p>
                </Block>:
                <>
                    <Block strong inset>
                        <p>
                            Selamat datang <b>{user.nama}</b><br/>
                            Silahkan kirimkan report pekerjaan Anda untuk hari ini ({moment().format("DD/MM/YYYY")}) dengan mengisikan form berikut.
                        </p>
                    </Block>
                    <BlockTitle>Report</BlockTitle>
                    <form onSubmit={submit}>
                        <List strongIos insetIos>
                            <ListInput label="Kondisi" name="status" type="select" dropdown defaultValue="Sehat" placeholder="Deskripsikan progress pekerjaan Anda disini.">
                                <option value="Sehat">Sehat</option>
                                <option value="Sakit">Sakit</option>
                            </ListInput>
                            <ListInput label="Notes" type="textarea" name="notes" placeholder="Deskripsikan progress pekerjaan Anda disini." inputClassName="!h-20" />

                            <ListInput
                                label="Lampiran (berbentuk zip)"
                                type="file"
                                name="files"
                                accept=".zip"
                                placeholder="Lampiran yang digunakan untuk mendukung notes/deskripsi pekerjaan"
                            />

                        </List>

                        <List strongIos insetIos>
                            <Button type="submit" className="k-color-brand-green">Submit</Button>
                        </List>
                    </form>
                </>
            }
        </div>
    </Layout>
}

export default DashboardPage