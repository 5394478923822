export function fetchAPI(path, opt = {}) {
    let additionalHeaders = {}
    if(opt && opt.hasOwnProperty("headers")){
        additionalHeaders = opt.headers
    }
    return fetch(`https://us-central1-trihaka-id.cloudfunctions.net/trihakaApp/${path}`, {
    // return fetch(`http://localhost:5001/trihaka-id/us-central1/trihakaApp/${path}`, {
        ...opt,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            ...additionalHeaders
        },
    })
        .then(r => r.json())
}