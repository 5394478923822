import logo from './logo.svg';
import './App.css';
import {BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes} from "react-router-dom";
import AuthProvider from "./AuthProvider";
import LoginPage from "./Pages/Login";
import ProtectedRoute from "./Protected";
import DashboardPage from "./Pages/Dashboard";
import {App} from "konsta/react";
import RegisterPage from "./Pages/Register";

function MyApp() {
  return <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/" element={
          <DashboardPage />
        } />
      </Routes>
    </BrowserRouter>
  </AuthProvider>;
}

export default MyApp;
