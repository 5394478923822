import {App, Block, BlockTitle, Button, Link, List, ListButton, ListInput, Navbar, Page} from "konsta/react";
import Layout from "../Components/Layout";
import {fetchAPI} from "../api";
import {useContext} from "react";
import {AuthContext} from "../AuthProvider";
import {Navigate, Link as Href} from "react-router-dom";

function LoginPage() {
    const {login, isLoggedIn, loading} = useContext(AuthContext)
    const submit = (e) => {
        e.preventDefault()

        const form = new FormData(e.target)
        loading(true)
        fetchAPI("staff/login", {
            method: "POST",
            body: form
        }).then(r => {
            if(r.ok){
                login(r.token)
            }else{
                alert(r.message)
            }
        }).catch(e => {
            alert(e)
        }).finally(() => {
            loading(false)
        })
    }
    if(isLoggedIn){
        return <Navigate to={"/"}/>
    }
    return <Layout title={"Staff"} subtitle={"PT. Tritama Hasil Karya Bersama"} right={<Link navbar><Href to={"/register"}>Register</Href></Link>}>
        <div className="relative">
            <BlockTitle>Login</BlockTitle>

            <form onSubmit={submit}>
                <List strongIos insetIos>
                    <ListInput label="Email" name="email" type="email" placeholder="Tulis email yang Anda gunakan" />

                    <ListInput
                        label="Password"
                        type="password"
                        name="password"
                        placeholder="Your password"
                    />

                </List>

                <List strongIos insetIos>
                    <Button type="submit" className="k-color-brand-green">Submit</Button>
                </List>
            </form>
        </div>
    </Layout>
}

export default LoginPage