import React, { createContext, useState } from 'react';

// Membuat objek konteks untuk Auth
export const AuthContext = createContext();

function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
    const [isLoading, setLoading] = useState(false);
    const [user, setUser] = useState({})
    // Fungsi untuk mengubah status login
    const login = (token) => {
        localStorage.setItem("token", token)
        setIsLoggedIn(true);
        setUser(JSON.parse(atob(token)))
    };

    // Fungsi untuk mengubah status logout
    const logout = () => {
        localStorage.removeItem("token")
        setIsLoggedIn(false);
    };

    const loading = (data) => {
        setLoading(data)
    }

    // Menyediakan nilai dan fungsi yang akan dibagikan ke komponen-komponen di dalamnya
    const authContextValue = {
        isLoggedIn,
        login,
        logout,
        user,
        isLoading,
        loading
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;