import {App, Block, BlockTitle, Button, Link, List, ListButton, ListInput, Navbar, Page} from "konsta/react";
import Layout from "../Components/Layout";
import {fetchAPI} from "../api";
import {useContext, useState} from "react";
import {AuthContext} from "../AuthProvider";
import {Link as Href, Navigate} from "react-router-dom";
import * as moment from "moment/moment";

function RegisterPage() {
    const {isLoggedIn, loading} = useContext(AuthContext)
    const [submitted, setSubmit] = useState(false)
    const submit = (e) => {
        e.preventDefault()

        const form = new FormData(e.target)
        loading(true)
        fetchAPI("staff/register", {
            method: "POST",
            body: form
        }).then(r => {
            if(r.ok){
                setSubmit(true)
            }else{
                alert(r.message)
            }
            loading(false)
        }).catch(e => {
            loading(false)
            alert(e)
        })
    }
    if(isLoggedIn || submitted){
        return <Navigate to={"/"}/>
    }
    return <Layout title={"Staff"} subtitle={"PT. Tritama Hasil Karya Bersama"} right={<Link navbar><Href to={"/login"}>Login</Href></Link>}>
        <div className="relative">
            <Block strong inset>
                <p>
                    Pastikan data yang Anda input benar. Anda hanya perlu mendaftarkan sekali saja. Untuk kode keamanan tanyakan ke pihak berwenang. Kalau udah daftar tinggal login aja, itu tombolnya disebelah kanan atas. Makasih...
                </p>
            </Block>
            <BlockTitle>Register</BlockTitle>

            <form onSubmit={submit}>
                <List strongIos insetIos>
                    <ListInput label="Nama" name="nama" type="text" placeholder="Nama Lengkap" />
                    <ListInput label="Email" name="email" type="email" placeholder="Tulis email yang Anda gunakan" />
                    <ListInput
                        label="Password"
                        type="password"
                        name="password"
                        placeholder="Tulis password yang Anda inginkan"
                    />
                    <ListInput
                        label="Konfirmasi Password"
                        type="password"
                        name="confirm-password"
                        placeholder="Tulis ulang password untuk konfirmasi"
                    />
                    <ListInput
                        label="Role"
                        type="select"
                        name="role"
                        dropdown
                        placeholder="Role pekerjaan"
                    >
                        <option value="backend">Backend Developer</option>
                        <option value="frontend">Frontend Developer</option>
                        <option value="mobile">Mobile Developer</option>
                        <option value="ui/ux">UI/UX</option>
                    </ListInput>
                    <ListInput
                        label="Kode keamanan"
                        type="password"
                        name="token"
                        placeholder="Kode keamanan"
                    />
                </List>

                <List strongIos insetIos>
                    <Button type="submit" className="k-color-brand-green">Submit</Button>
                </List>
            </form>
        </div>
    </Layout>
}

export default RegisterPage